import {
  GET_FIXED_ASSET_VERIFICATION,
  GET_PROFESSIONAL_VERIFICATION,
  SET_LOADING_VERIFICATION,
  FIXED_ASSET_VERIFICATION_ERROR,
  PROFESSIONAL_VERIFICATION_ERROR,
  CLEAR_DATA_FIXED_ASSET_VERIFICATION,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_FIXED_ASSET_VERIFICATION:
      return {
        ...state,
        fixedAsset: action.payload,
        loading: false,
        errors: null
      };
    case GET_PROFESSIONAL_VERIFICATION:
      return {
        ...state,
        professional: action.payload,
        loading: false,
        errors: null
      };
    case SET_LOADING_VERIFICATION:
      return {
        ...state,
        loading: true,
        errors: false
      };
    case FIXED_ASSET_VERIFICATION_ERROR:
    case PROFESSIONAL_VERIFICATION_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_DATA_FIXED_ASSET_VERIFICATION:
      return {
        ...state,
        fixedAsset: null,
        professional: null,
        loading: true,
        errors: null,
      };
    default:
      return state;
  }
};
