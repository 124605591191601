import {
  GET_PROFESSIONALS,
  GET_PROFESSIONAL,
  SET_LOADING_PROFESSIONAL,
  SET_LOADING_SUBSCRIPTION,
  SET_LOADING_PAYMENT,
  CREATE_PROFESSIONAL_SUCCESS,
  CREATE_CARNET_PROFESSIONAL_SUCCESS,
  CREATE_CERTIFICATE_PROFESSIONAL_SUCCESS,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_PAYMENT_SUCCESS,
  UPDATE_PROFESSIONAL_SUCCESS,
  UPDATE_STATE_PROFESSIONAL_SUCCESS,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_PROFESSIONAL_SUCCESS,
  DELETE_PAYMENT_SUCCESS,
  PROFESSIONAL_ERROR,
  SUBSCRIPTION_ERROR,
  PAYMENT_ERROR,
  CLEAR_ERRORS_PROFESSIONAL,
  CLEAR_ERRORS_SUBSCRIPTION,
  RESET_SUBSCRIPTION,
  RESET_PAYMENT,
  CLEAR_DATA_PROFESSIONAL
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_PROFESSIONALS:
      return {
        ...state,
        professionals: action.payload,
        errorsProfessional: null,
        loadingProfessional: false
      };
    case GET_PROFESSIONAL:
      return {
        ...state,
        professional: action.payload,
        loadingProfessional: false,
        errorsProfessional: false,
        loadingSubscription: false,
        errorsSubscription: null,
        loadingPayment: false,
        errorsPayment: null,
      };
    case SET_LOADING_PROFESSIONAL:
      return {
        ...state,
        loadingProfessional: action.payload
      };
    case SET_LOADING_SUBSCRIPTION:
      return {
        ...state,
        loadingSubscription: true
      };
    case SET_LOADING_PAYMENT:
      return {
        ...state,
        loadingPayment: true
      };
    case PROFESSIONAL_ERROR:
      return {
        ...state,
        errorsProfessional: action.payload,
        loadingProfessional: false
      };
    case SUBSCRIPTION_ERROR:
      return {
        ...state,
        errorsSubscription: action.payload,
        loadingSubscription: false
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        errorsPayment: action.payload,
        loadingPayment: false
      };
    case CREATE_PROFESSIONAL_SUCCESS:
    case UPDATE_PROFESSIONAL_SUCCESS:
    case UPDATE_STATE_PROFESSIONAL_SUCCESS:
    case DELETE_PROFESSIONAL_SUCCESS:
    case CREATE_CARNET_PROFESSIONAL_SUCCESS:
    case CREATE_CERTIFICATE_PROFESSIONAL_SUCCESS:
      return {
        ...state,
        errorsProfessional: null,
        loadingProfessional: false,
      };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        errorsSubscription: null,
        loadingSubscription: false,
        createSubscriptionSuccess: true
      };
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        errorsSubscription: null,
        loadingSubscription: false,
        deleteSubscriptionSuccess: true
      };
    case CREATE_PAYMENT_SUCCESS:
    case DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        errorsPayment: null,
        loadingPayment: false,
      };
    case CLEAR_ERRORS_PROFESSIONAL:
      return {
        ...state,
        errorsProfessional: null,
        loadingProfessional: false
      };
    case CLEAR_ERRORS_SUBSCRIPTION:
      return {
        ...state,
        errorsSubscription: null,
        loadingSubscription: false,
        professional: null
      };
    case RESET_SUBSCRIPTION:
      return {
        ...state,
        loadingSubscription: false,
        errorsSubscription: null,
        createSubscriptionSuccess: false,
        deleteSubscriptionSuccess: false,
      };
    case RESET_PAYMENT:
      return {
        ...state,
        errorsPayment: null,
        loadingPayment: false
      };
    case CLEAR_DATA_PROFESSIONAL:
      return {
        professionals: null,
        professional: null,
        loadingProfessional: true,
        errorsProfessional: null,
        createSubscriptionSuccess: false,
        deleteSubscriptionSuccess: false,
        loadingSubscription: false,
        errorsSubscription: null,
        loadingPayment: false,
        errorsPayment: null,
      };
    default:
      return state;
  }
};
