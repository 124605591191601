import React, { useReducer } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import FixedAssetContext from './fixedAssetContext';
import fixedAssetReducer from './fixedAssetReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import { configJSON } from '../../config/requestConfig';
import {
  GET_FIXED_ASSETS,
  CLEAR_DATA_FIXED_ASSET,
  GET_FIXED_ASSET,
  SET_LOADING_FIXED_ASSET,
  FIXED_ASSET_ERROR,
  CLEAR_ERRORS_FIXED_ASSET,
  GET_FIXED_ASSETS_QR_FILE,
} from '../types';

const FixedAssetState = (props) => {
  const initialState = {
    fixedAssets: null,
    fixedAsset: null,
    loading: true,
    errors: null,
  };

  const [state, dispatch] = useReducer(fixedAssetReducer, initialState);

  // Fetch all fixedAssets
  const getFixedAssets = async ({ keyword = '', field = '', pageNumber = '', rowsPerPage = '' } = {}) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/fixed-assets?keyword=${keyword}&field=${field}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
      )
      dispatch({
        type: GET_FIXED_ASSETS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: FIXED_ASSET_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Fetch single fixedAsset
  const getFixedAsset = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/fixed-assets/${id}`);
      dispatch({
        type: GET_FIXED_ASSET,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: FIXED_ASSET_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Create a new fixedAsset
  const createFixedAsset = async (formData, options = {}) => {
    const { history, enqueueSnackbar } = options
    try {
      setLoading(true);
      await axios.post('/api/v1/fixed-assets', formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/fixed-assets/listing');
    } catch (error) {
      dispatch({
        type: FIXED_ASSET_ERROR,
        payload: error.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Update fixedAsset
  const updateFixedAsset = async (_id, formData, options = {}) => {
    const { history, enqueueSnackbar } = options
    try {
      setLoading(true);
      await axios.put(`/api/v1/fixed-assets/${_id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/fixed-assets/listing');
    } catch (err) {
      dispatch({
        type: FIXED_ASSET_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Update state fixedAsset
  const updateStateFixedAsset = async (_id, formData, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.patch(
        `/api/v1/fixed-assets/${_id}`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      return true
    } catch (err) {
      dispatch({
        type: FIXED_ASSET_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Delete fixedAsset
  const deleteFixedAsset = async (_id, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/fixed-assets/${_id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      return true
    } catch (err) {
      dispatch({
        type: FIXED_ASSET_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Download pdf registration fixed assets qr
  const generateFixedAssetsQR = async (formData) => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/reports/fixed-assets/qr`, formData, configJSON);

      // Get report generated and download client
      const resFile = await axios.get(`/api/v1/reports/qr_fixed_assets.pdf`, {
        responseType: 'blob',
      });
      const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `activos_fijos-qr-${Date.now()}.pdf`);
      dispatch({
        type: GET_FIXED_ASSETS_QR_FILE,
      });
    } catch (err) {
      dispatch({
        type: FIXED_ASSET_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Generate and download pdf qr
  const generateFixedAssetQR = async (id) => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/reports/fixed-assets/${id}/qr`);

      // Get report generated and download client
      const resFile = await axios.get(`/api/v1/reports/qr_fixed_asset.pdf`, {
        responseType: 'blob',
      });
      const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `activo_fijo_qr-${Date.now()}.pdf`);
      dispatch({
        type: GET_FIXED_ASSETS_QR_FILE,
      });
    } catch (err) {
      dispatch({
        type: FIXED_ASSET_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_FIXED_ASSET,
      payload: state,
    });
  };

  // Clear errors
  const clearErrors = async () => {
    dispatch({
      type: CLEAR_ERRORS_FIXED_ASSET,
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_FIXED_ASSET,
    });
  };

  return (
    <FixedAssetContext.Provider
      value={{
        fixedAssets: state.fixedAssets,
        fixedAsset: state.fixedAsset,
        errors: state.errors,
        loading: state.loading,
        getFixedAssets,
        getFixedAsset,
        createFixedAsset,
        updateFixedAsset,
        updateStateFixedAsset,
        deleteFixedAsset,
        generateFixedAssetsQR,
        generateFixedAssetQR,
        setLoading,
        clearErrors,
        clearData,
      }}
    >
      {props.children}
    </FixedAssetContext.Provider>
  );
};

export default FixedAssetState;
