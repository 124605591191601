import React, { useReducer } from 'react';
import axios from 'axios';
import ReportContext from './reportContext';
import reportReducer from './reportReducer';
import {
  GET_PROFESSIONALS_REPORT,
  GET_MEMBERSHIPS_PROFESSIONALS_REPORT,
  GET_FIXED_ASSETS_REPORT,
  GET_EXPENSES_TRANSACTION_REPORT,
  GET_INCOMES_TRANSACTION_REPORT,
  SET_LOADING_REPORT,
  REPORT_ERROR,
  CLEAR_ERRORS_REPORT,
  CLEAR_DATA_REPORT,
} from '../types';

const ReportState = (props) => {
  const initialState = {
    professionals: null,
    fixedAssets: null,
    transactions: null,
    loading: true,
    errors: null,
  };

  const [state, dispatch] = useReducer(reportReducer, initialState);

  const getProfessionalsReport = async ({ department = '', isProfessionalActive = '' }) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/reports/professionals?department=${department}&isProfessionalActive=${isProfessionalActive}`);
      dispatch({
        type: GET_PROFESSIONALS_REPORT,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: REPORT_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  const getProfMembershipsReport = async ({ department = '', entity = '', subscription = '', paymentStatus = '' }) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/reports/professionals/memberships?department=${department}&entity=${entity}&subscription=${subscription}&paymentStatus=${paymentStatus}`);
      dispatch({
        type: GET_MEMBERSHIPS_PROFESSIONALS_REPORT,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: REPORT_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  const getFixedAssetsReport = async ({ office = '', state = '' }) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/reports/fixed-assets?office=${office}&state=${state}`);
      dispatch({
        type: GET_FIXED_ASSETS_REPORT,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: REPORT_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  const getExpensesTransactionReport = async ({
    dateBegin = '',
    dateEnd = '',
    user = '',
    typeTransaction = ''
  }) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/reports/transactions?dateBegin=${dateBegin}&dateEnd=${dateEnd}&user=${user}&typeTransaction=${typeTransaction}`);
      dispatch({
        type: GET_EXPENSES_TRANSACTION_REPORT,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: REPORT_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  const getIncomesTransactionReport = async ({
    dateBegin = '',
    dateEnd = '',
    user = '',
    typeTransaction = ''
  }) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/reports/transactions?dateBegin=${dateBegin}&dateEnd=${dateEnd}&user=${user}&typeTransaction=${typeTransaction}`);
      dispatch({
        type: GET_INCOMES_TRANSACTION_REPORT,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: REPORT_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_REPORT,
      payload: state
    });
  };

  // Clear errors
  const clearErrors = async () => {
    dispatch({
      type: CLEAR_ERRORS_REPORT,
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_REPORT,
    });
  };

  return (
    <ReportContext.Provider
      value={{
        professionals: state.professionals,
        fixedAssets: state.fixedAssets,
        transactions: state.transactions,
        errors: state.errors,
        loading: state.loading,
        getProfessionalsReport,
        getProfMembershipsReport,
        getFixedAssetsReport,
        getExpensesTransactionReport,
        getIncomesTransactionReport,
        setLoading,
        clearErrors,
        clearData,
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};

export default ReportState;
