export const msgSuccessSB = 'Operación realizada exitosamente.'
export const msgFailureSB = 'Ocurrio un problema al realizar la operación.'

export const optionsSuccessSB = {
  variant: 'success',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
}

export const optionsFailureSB = {
  variant: 'error',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
}
