import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

// components
import CircularLoading from './Loading/CircularLoading';

// context
import AuthState from '../context/auth/AuthState';
import UserState from '../context/user/UserState';
import AuthContext from '../context/auth/authContext';
import DepartmentState from '../context/department/DepartmentState';
import ProfessionalState from '../context/professional/ProfessionalState';
import EntityState from '../context/entity/EntityState';
import AreaState from '../context/area/AreaState';
import PersonState from '../context/person/PersonState';
import OfficeState from '../context/office/OfficeState';
import FixedAssetState from '../context/fixedAsset/FixedAssetState';
import VerificationState from '../context/verification/VerificationState';
import ConfigState from '../context/config/ConfigState';
import ReportState from '../context/report/ReportState';
import ProviderState from '../context/provider/ProviderState';
import TransactionState from '../context/transaction/TransactionState';

// loading
import 'react-loading-skeleton/dist/skeleton.css';

// config
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

// pages
const Layout = lazy(() => import('./Layout'));
const Login = lazy(() => import('../pages/login/Login'));
const Error = lazy(() => import('../pages/error/Error'));
const FixedAssetVerification = lazy(() => import('../pages/verifications/FixedAssetVerification'));
const ProfessionalVerification = lazy(() => import('../pages/verifications/ProfessionalVerification'));

export default function App() {
  return (
    <TransactionState>
      <ProviderState>
        <ReportState>
          <ConfigState>
            <VerificationState>
              <PersonState>
                <OfficeState>
                  <AreaState>
                    <FixedAssetState>
                      <EntityState>
                        <ProfessionalState>
                          <DepartmentState>
                            <UserState>
                              <AuthState>
                                <SnackbarProvider maxSnack={3}>
                                  <BrowserRouter>
                                    <Switch>
                                      <Suspense
                                        fallback={
                                          <CircularLoading height={window.innerHeight} />
                                        }
                                      >
                                        <Route
                                          exact
                                          path="/"
                                          render={() => <Redirect to="/app/dashboard" />}
                                        />
                                        <Route
                                          exact
                                          path="/app"
                                          render={() => <Redirect to="/app/dashboard" />}
                                        />
                                        <PrivateRoute path="/app" component={Layout} />
                                        <PublicRoute path="/login" component={Login} />
                                        <Route
                                          path="/verifications/fixed-assets/:id"
                                          component={FixedAssetVerification}
                                          exact
                                        />
                                        <Route
                                          path="/verifications/professionals/:id"
                                          component={ProfessionalVerification}
                                          exact
                                        />
                                      </Suspense>
                                      <Route render={() => <Error />} />
                                    </Switch>
                                  </BrowserRouter>
                                </SnackbarProvider>
                              </AuthState>
                            </UserState>
                          </DepartmentState>
                        </ProfessionalState>
                      </EntityState>
                    </FixedAssetState>
                  </AreaState>
                </OfficeState>
              </PersonState>
            </VerificationState>
          </ConfigState>
        </ReportState>
      </ProviderState>
    </TransactionState>
  );

  function PrivateRoute({ component, ...rest }) {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, loading, user, loadUser, logout } = authContext;

    useEffect(() => {
      loadUser();
      // eslint-disable-next-line
    }, []);

    // Check if user are blocked
    useEffect(() => {
      if (!loading && user?.data) {
        if (user && !user.data?.state) {
          logout();
        }
      }
      // eslint-disable-next-line
    }, [loading, user?.data]);

    return (
      <>
        {isAuthenticated && loading === false && user?.data?.state && (
          <Route
            {...rest}
            render={(props) => React.createElement(component, props)}
          />
        )}

        {!isAuthenticated && loading === false && (
          <Route
            {...rest}
            render={(props) => (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {
                    from: props.location,
                  },
                }}
              />
            )}
          />
        )}
      </>
    );
  }

  function PublicRoute({ component, ...rest }) {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, loading, user, loadUser, logout } = authContext;

    useEffect(() => {
      loadUser();
      // eslint-disable-next-line
    }, []);

    // Check if user are blocked
    useEffect(
      () => {
        if (!loading) {
          if (user && !user.data?.state) {
            logout();
          }
        }
      },
      // eslint-disable-next-line
      [loading, user]
    );

    return (
      <>
        {isAuthenticated && loading === false && user?.data?.state && (
          <Route
            {...rest}
            render={(props) => (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            )}
          />
        )}

        <Route
          {...rest}
          render={(props) => React.createElement(component, props)}
        />
      </>
    );
  }
}
