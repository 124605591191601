import React, { useReducer } from 'react';
import axios from 'axios';
import PersonContext from './personContext';
import personReducer from './personReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import { configJSON } from '../../config/requestConfig';
import {
  GET_PERSONS,
  GET_PERSON,
  SET_LOADING_PERSON,
  CREATE_PERSON_SUCCESS,
  UPDATE_PERSON_SUCCESS,
  DELETE_PERSON_SUCCESS,
  PERSON_ERROR,
  CLEAR_ERRORS_PERSON,
  CLEAR_DATA_PERSON,
} from '../types';

const PersonState = (props) => {
  const initialState = {
    persons: null,
    person: null,
    loading: true,
    errors: null,
  };

  const [state, dispatch] = useReducer(personReducer, initialState);

  // Fetch all persons
  const getPersons = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/persons`);
      dispatch({
        type: GET_PERSONS,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Fetch single person
  const getPerson = async (id) => {
    try {
      // setLoading(true);
      const res = await axios.get(`/api/v1/persons/${id}`);
      dispatch({
        type: GET_PERSON,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Create a new person
  const createPerson = async (formData, options = {}) => {
    const { enqueueSnackbar } = options
    setLoading(true);
    try {
      await axios.post('/api/v1/persons', formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: CREATE_PERSON_SUCCESS,
      });
      return true;
    } catch (error) {
      dispatch({
        type: PERSON_ERROR,
        payload: error.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Update person
  const updatePerson = async (id, formData, options = {}) => {
    const { enqueueSnackbar } = options
    try {
      setLoading(true);
      await axios.put(`/api/v1/persons/${id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: UPDATE_PERSON_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Delete person
  const deletePerson = async (id, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/persons/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: DELETE_PERSON_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_PERSON,
      payload: state
    });
  };

  // Clear errors
  const clearErrors = async () => {
    dispatch({
      type: CLEAR_ERRORS_PERSON,
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_PERSON,
    });
  };

  return (
    <PersonContext.Provider
      value={{
        persons: state.persons,
        person: state.person,
        errors: state.errors,
        loading: state.loading,
        getPersons,
        getPerson,
        createPerson,
        updatePerson,
        deletePerson,
        setLoading,
        clearErrors,
        clearData,
      }}
    >
      {props.children}
    </PersonContext.Provider>
  );
};

export default PersonState;
