// Auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';

// Users
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const SET_LOADING_USERS = "SET_LOADING_USERS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_STATE_USER_SUCCESS = "UPDATE_STATE_USER_SUCCESS"
export const UPDATE_AUTH_USER_SUCCESS = "UPDATE_AUTH_USER_SUCCESS"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const USER_ERROR = "USER_ERROR";
export const CLEAR_ERRORS_USER = "CLEAR_ERRORS_USER";
export const CLEAR_DATA_USER = "CLEAR_DATA_USER";

// Departments
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const SET_LOADING_DEPARTMENT = 'SET_LOADING_DEPARTMENT';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';
export const CLEAR_DEPARTMENT_DATA = 'CLEAR_DEPARTMENT_DATA';

// Processes
export const GET_CARD_PROCESS = 'GET_CARD_PROCESS';
export const SET_LOADING_CARD_PROCESS = 'SET_LOADING_CARD_PROCESS';
export const CREATE_PROCESS_SUCCESS = 'CREATE_PROCESS_SUCCESS';
export const UPDATE_PROCESS_SUCCESS = 'UPDATE_PROCESS_SUCCESS';
export const CARD_PROCESS_ERROR = 'CARD_PROCESS_ERROR';
export const CLEAR_ERRORS_CARD_PROCESS = 'CLEAR_ERRORS_CARD_PROCESS';
export const CLEAR_DATA_CARD_PROCESS = 'CLEAR_DATA_CARD_PROCESS';

// User Processes
export const GET_USER_PROCESS = 'GET_USER_PROCESS';
export const SET_LOADING_USER_PROCESS = 'SET_LOADING_USER_PROCESS';
export const USER_PROCESS_ERROR = 'USER_PROCESS_ERROR';
export const CLEAR_DATA_USER_PROCESS = 'CLEAR_DATA_USER_PROCESS';

// Professionals
export const GET_PROFESSIONALS = "GET_PROFESSIONALS";
export const GET_PROFESSIONAL = "GET_PROFESSIONAL";
export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS";
export const GET_MEMBERSHIP = "GET_MEMBERSHIP";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_PAYMENT = "GET_PAYMENT";
export const SET_LOADING_PROFESSIONAL = "SET_LOADING_PROFESSIONAL";
export const SET_LOADING_SUBSCRIPTION = "SET_LOADING_SUBSCRIPTION";
export const SET_LOADING_PAYMENT = "SET_LOADING_PAYMENT";
export const CREATE_PROFESSIONAL_SUCCESS = "CREATE_PROFESSIONAL_SUCCESS"
export const CREATE_CARNET_PROFESSIONAL_SUCCESS = "CREATE_CARNET_PROFESSIONAL_SUCCESS"
export const CREATE_CERTIFICATE_PROFESSIONAL_SUCCESS = "CREATE_CERTIFICATE_PROFESSIONAL_SUCCESS"
export const CREATE_MEMBERSHIP_SUCCESS = "CREATE_MEMBERSHIP_SUCCESS"
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS"
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS"
export const UPLOAD_IMAGE_PERSON_SUCCESS = 'UPLOAD_IMAGE_PERSON_SUCCESS'
export const UPLOAD_FILE_PROFESSIONAL_SUCCESS = 'UPLOAD_FILE_PROFESSIONAL_SUCCESS'
export const UPDATE_PROFESSIONAL_SUCCESS = "UPDATE_PROFESSIONAL_SUCCESS"
export const UPDATE_STATE_PROFESSIONAL_SUCCESS = "UPDATE_STATE_PROFESSIONAL_SUCCESS"
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS'
export const DELETE_PROFESSIONAL_SUCCESS = "DELETE_PROFESSIONAL_SUCCESS"
export const DELETE_MEMBERSHIP_SUCCESS = "DELETE_MEMBERSHIP_SUCCESS"
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS"
export const PROFESSIONAL_ERROR = "PROFESSIONAL_ERROR";
export const SUBSCRIPTION_ERROR = "SUBSCRIPTION_ERROR";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const CLEAR_ERRORS_PROFESSIONAL = "CLEAR_ERRORS_PROFESSIONAL";
export const CLEAR_ERRORS_SUBSCRIPTION = "CLEAR_ERRORS_SUBSCRIPTION";
export const RESET_SUBSCRIPTION = "RESET_SUBSCRIPTION";
export const RESET_PAYMENT = "RESET_PAYMENT";
export const CLEAR_DATA_PROFESSIONAL = "CLEAR_DATA_PROFESSIONAL";

// Entities
export const GET_ENTITIES = "GET_ENTITIES"
export const GET_ENTITY = "GET_ENTITY"
export const GET_SUBSCRIPTIONS_ENTITY = "GET_SUBSCRIPTIONS_ENTITY"
export const GET_SUBSCRIPTION_ENTITY = "GET_SUBSCRIPTION_ENTITY"
export const SET_LOADING_ENTITY = "SET_LOADING_ENTITY"
export const SET_SUBSCRIPTION_LOADING_ENTITY = "SET_SUBSCRIPTION_LOADING_ENTITY"
export const CREATE_SUBSCRIPTION_ENTITY_SUCCESS = "CREATE_SUBSCRIPTION_ENTITY_SUCCESS"
export const UPDATE_SUBSCRIPTION_ENTITY_SUCCESS = "UPDATE_SUBSCRIPTION_ENTITY_SUCCESS"
export const DELETE_SUBSCRIPTION_ENTITY_SUCCESS = "DELETE_SUBSCRIPTION_ENTITY_SUCCESS"
// export const UPLOAD_CARD_SUBSCRIPTION_SUCCESS = "UPLOAD_CARD_SUBSCRIPTION_SUCCESS"
// export const UPLOAD_CERTIFICATE_SUBSCRIPTION_SUCCESS = "UPLOAD_CERTIFICATE_SUBSCRIPTION_SUCCESS"
export const ENTITY_ERROR = "ENTITY_ERROR"
export const SUBSCRIPTION_ENTITY_ERROR = "SUBSCRIPTION_ENTITY_ERROR"
export const CLEAR_SUBSCRIPTION_SELECTED_ENTITY = "CLEAR_SUBSCRIPTION_SELECTED_ENTITY"
export const CLEAR_DATA_ENTITY = "CLEAR_DATA_ENTITY"

// Persons
export const GET_PERSONS = 'GET_PERSONS';
export const GET_PERSON = 'GET_PERSON';
export const SET_LOADING_PERSON = 'SET_LOADING_PERSON';
export const CREATE_PERSON_SUCCESS = 'CREATE_PERSON_SUCCESS';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';
export const CREATE_OFFICE_SUCCESS = 'CREATE_OFFICE_SUCCESS';
export const UPDATE_OFFICE_SUCCESS = 'UPDATE_OFFICE_SUCCESS';
export const DELETE_OFFICE_SUCCESS = 'DELETE_OFFICE_SUCCESS';
export const PERSON_ERROR = 'PERSON_ERROR';
export const CLEAR_ERRORS_PERSON = 'CLEAR_ERRORS_PERSON';
export const CLEAR_DATA_PERSON = 'CLEAR_DATA_PERSON';

// Offices
export const GET_OFFICES = 'GET_OFFICES';
export const GET_OFFICE = 'GET_OFFICE';
export const SET_LOADING_OFFICE = 'SET_LOADING_OFFICE';
export const OFFICE_ERROR = 'OFFICE_ERROR';
export const CLEAR_ERRORS_OFFICE = 'CLEAR_ERRORS_OFFICE';
export const CLEAR_DATA_OFFICE = 'CLEAR_DATA_OFFICE';

// Areas
export const GET_AREAS = 'GET_AREAS';
export const GET_AREA = 'GET_AREA';
export const SET_LOADING_AREA = 'SET_LOADING_AREA';
export const CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS';
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS';
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS';
export const AREA_ERROR = 'AREA_ERROR';
export const CLEAR_ERRORS_AREA = 'CLEAR_ERRORS_AREA';
export const CLEAR_DATA_AREA = 'CLEAR_DATA_AREA';

// Fixed Asset
export const GET_FIXED_ASSETS = 'GET_FIXED_ASSETS';
export const GET_FIXED_ASSETS_QR_FILE = 'GET_FIXED_ASSETS_QR_FILE';
export const GET_FIXED_ASSET = 'GET_FIXED_ASSET';
export const SET_LOADING_FIXED_ASSET = 'SET_LOADING_FIXED_ASSET';
export const FIXED_ASSET_ERROR = 'FIXED_ASSET_ERROR';
export const CLEAR_ERRORS_FIXED_ASSET = 'CLEAR_ERRORS_FIXED_ASSET';
export const CLEAR_DATA_FIXED_ASSET = 'CLEAR_DATA_FIXED_ASSET';

// Verification
export const GET_FIXED_ASSET_VERIFICATION = 'GET_FIXED_ASSET_VERIFICATION';
export const GET_PROFESSIONAL_VERIFICATION = 'GET_PROFESSIONAL_VERIFICATION';
export const SET_LOADING_VERIFICATION = 'SET_LOADING_VERIFICATION';
export const FIXED_ASSET_VERIFICATION_ERROR = 'FIXED_ASSET_VERIFICATION_ERROR';
export const PROFESSIONAL_VERIFICATION_ERROR = 'PROFESSIONAL_VERIFICATION_ERROR';
export const CLEAR_DATA_FIXED_ASSET_VERIFICATION = 'CLEAR_DATA_FIXED_ASSET_VERIFICATION';

// Reports
export const GET_PROFESSIONALS_REPORT = 'GET_PROFESSIONALS_REPORT';
export const GET_MEMBERSHIPS_PROFESSIONALS_REPORT = 'GET_MEMBERSHIPS_PROFESSIONALS_REPORT';
export const GET_FIXED_ASSETS_REPORT = 'GET_FIXED_ASSETS_REPORT';
export const GET_EXPENSES_TRANSACTION_REPORT = 'GET_EXPENSES_TRANSACTION_REPORT';
export const GET_INCOMES_TRANSACTION_REPORT = 'GET_INCOMES_TRANSACTION_REPORT';
export const SET_LOADING_REPORT = 'SET_LOADING_REPORT';
export const REPORT_ERROR = 'REPORT_ERROR';
export const CLEAR_ERRORS_REPORT = 'CLEAR_ERRORS_REPORT';
export const CLEAR_DATA_REPORT = 'CLEAR_DATA_REPORT';

// Providers
export const GET_PROVIDERS = "GET_PROVIDERS"
export const GET_PROVIDER = "GET_PROVIDER"
export const SET_LOADING_PROVIDER = "SET_LOADING_PROVIDER"
export const PROVIDER_CREATE_SUCCESS = "PROVIDER_CREATE_SUCCESS"
export const PROVIDER_UPDATE_SUCCESS = "PROVIDER_UPDATE_SUCCESS"
export const PROVIDER_DELETE_SUCCESS = "PROVIDER_DELETE_SUCCESS"
export const PROVIDER_ERROR = "PROVIDER_ERROR"
export const CLEAR_DATA_PROVIDER = "CLEAR_DATA_PROVIDER"

// Transactions
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const GET_TRANSACTION = 'GET_TRANSACTION'
export const SET_LOADING_TRANSACTION = 'SET_LOADING_TRANSACTION'
export const TRANSACTION_CREATE_SUCCESS = 'TRANSACTION_CREATE_SUCCESS'
export const TRANSACTION_UPDATE_SUCCESS = 'TRANSACTION_UPDATE_SUCCESS'
export const TRANSACTION_DELETE_SUCCESS = 'TRANSACTION_DELETE_SUCCESS'
export const TRANSACTION_PDF_SUCCESS = 'TRANSACTION_PDF_SUCCESS'
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR'
export const CLEAR_DATA_TRANSACTION = 'CLEAR_DATA_TRANSACTION'