import {
  GET_PROFESSIONALS_REPORT,
  GET_MEMBERSHIPS_PROFESSIONALS_REPORT,
  GET_FIXED_ASSETS_REPORT,
  GET_EXPENSES_TRANSACTION_REPORT,
  GET_INCOMES_TRANSACTION_REPORT,
  SET_LOADING_REPORT,
  REPORT_ERROR,
  CLEAR_ERRORS_REPORT,
  CLEAR_DATA_REPORT,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_PROFESSIONALS_REPORT:
    case GET_MEMBERSHIPS_PROFESSIONALS_REPORT:
      return {
        ...state,
        professionals: action.payload,
        errors: null,
        loading: false,
      };
    case GET_FIXED_ASSETS_REPORT:
      return {
        ...state,
        fixedAssets: action.payload,
        errors: null,
        loading: false,
      };
    case GET_EXPENSES_TRANSACTION_REPORT:
    case GET_INCOMES_TRANSACTION_REPORT:
      return {
        ...state,
        transactions: action.payload,
        errors: null,
        loading: false,
      };
    case SET_LOADING_REPORT:
      return {
        ...state,
        loading: action.payload,
        errors: null,
      };
    case REPORT_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_REPORT:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_REPORT:
      return {
        professionals: null,
        fixedAssets: null,
        transactions: null,
        loading: true,
        errors: null,
      };
    default:
      return state;
  }
};
