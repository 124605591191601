import React, { useReducer } from 'react'
import axios from 'axios'
import { saveAs } from 'file-saver'

import { configJSON, configMultiPart } from '../../config/requestConfig'
import ProfessionalContext from './professionalContext'
import professionalReducer from './professionalReducer'
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar'
import {
  GET_PROFESSIONALS,
  GET_PROFESSIONAL,
  SET_LOADING_PROFESSIONAL,
  SET_LOADING_SUBSCRIPTION,
  SET_LOADING_PAYMENT,
  CREATE_PROFESSIONAL_SUCCESS,
  CREATE_CARNET_PROFESSIONAL_SUCCESS,
  CREATE_CERTIFICATE_PROFESSIONAL_SUCCESS,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_PAYMENT_SUCCESS,
  UPLOAD_IMAGE_PERSON_SUCCESS,
  UPLOAD_FILE_PROFESSIONAL_SUCCESS,
  UPDATE_PROFESSIONAL_SUCCESS,
  UPDATE_STATE_PROFESSIONAL_SUCCESS,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_PROFESSIONAL_SUCCESS,
  DELETE_PAYMENT_SUCCESS,
  PROFESSIONAL_ERROR,
  SUBSCRIPTION_ERROR,
  PAYMENT_ERROR,
  CLEAR_ERRORS_PROFESSIONAL,
  CLEAR_ERRORS_SUBSCRIPTION,
  RESET_SUBSCRIPTION,
  RESET_PAYMENT,
  CLEAR_DATA_PROFESSIONAL,
} from '../types'

const ProfessionalState = (props) => {
  const initialState = {
    professionals: null,
    professional: null,
    loadingProfessional: true,
    errorsProfessional: null,
    createSubscriptionSuccess: false,
    deleteSubscriptionSuccess: false,
    loadingSubscription: false,
    errorsSubscription: null,
    loadingPayment: false,
    errorsPayment: null,
  }

  const [state, dispatch] = useReducer(professionalReducer, initialState)

  // Fetch all professionals
  const getProfessionals = async ({ keyword = '', field = '', pageNumber = '', rowsPerPage = '' } = {}) => {
    try {
      setProfessionalLoading(true)
      const res = await axios.get(
        `/api/v1/professionals?keyword=${keyword}&field=${field}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
      )
      dispatch({
        type: GET_PROFESSIONALS,
        payload: res.data?.data
      })
    } catch (err) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Fetch single professional
  const getProfessional = async (id) => {
    try {
      setProfessionalLoading(true)
      const res = await axios.get(`/api/v1/professionals/${id}`)
      dispatch({
        type: GET_PROFESSIONAL,
        payload: res.data?.data,
      })
    } catch (err) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Create a new professional
  const createProfessional = async (formData, formDataImage, formDataFile, options) => {
    const { navigate, enqueueSnackbar } = options
    try {
      setProfessionalLoading(true)
      const res = await axios.post('/api/v1/professionals', formData, configJSON)
      dispatch({
        type: CREATE_PROFESSIONAL_SUCCESS,
      })
      // Get id person created
      const personId = res.data?.data?.person;
      // Upload image to uploads folder
      if (formDataImage.get('image') instanceof File) {
        await uploadImagePerson(formDataImage, personId)
      }
      // Upload file to uploads folder
      if (formDataFile.get('file') instanceof File) {
        const professionalId = res.data?.data?._id;
        await uploadFileProfessional(formDataFile, professionalId)
      }
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      navigate.push('/app/cards/professionals/listing')
    } catch (error) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: error.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Update professional
  const updateProfessional = async (id, formData, formDataImage, formDataFile, options) => {
    const { navigate, enqueueSnackbar } = options
    try {
      setProfessionalLoading(true)
      const res = await axios.put(`/api/v1/professionals/${id}`, formData, configJSON)
      dispatch({
        type: UPDATE_PROFESSIONAL_SUCCESS,
      })
      // Get id person updated
      const personId = res.data?.data?.person;
      // Upload image to uploads folder
      if (formDataImage.get('image') instanceof File) {
        await uploadImagePerson(formDataImage, personId)
      }
      // Upload file to uploads folder
      if (formDataFile.get('file') instanceof File) {
        const professionalId = res.data?.data?._id;
        await uploadFileProfessional(formDataFile, professionalId)
      }
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      navigate.push('/app/cards/professionals/listing')
    } catch (err) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Update state professional
  const updateStateProfessional = async (id, formData, enqueueSnackbar) => {
    try {
      setProfessionalLoading(true)
      await axios.patch(`/api/v1/professionals/${id}`, formData, configJSON)
      dispatch({
        type: UPDATE_STATE_PROFESSIONAL_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true;
    } catch (err) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
      return false;
    }
  }

  // Delete professional
  const deleteProfessional = async (id, enqueueSnackbar) => {
    try {
      setProfessionalLoading(true)
      await axios.delete(`/api/v1/professionals/${id}`)
      dispatch({
        type: DELETE_PROFESSIONAL_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (err) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Upload image
  const uploadImagePerson = async (formDataFile, id) => {
    try {
      setProfessionalLoading(true)
      // Return path file uploaded
      await axios.post(
        `/api/v1/uploads/persons/${id}`,
        formDataFile,
        configMultiPart
      )
      dispatch({
        type: UPLOAD_IMAGE_PERSON_SUCCESS
      })
    } catch (error) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: error.response?.data?.errors,
      })
    }
  }

  // Upload file
  const uploadFileProfessional = async (formDataFile, professionalId) => {
    try {
      setProfessionalLoading(true)
      // Return path file uploaded
      await axios.post(
        `/api/v1/uploads/professionals/${professionalId}`,
        formDataFile,
        configMultiPart
      )
      dispatch({
        type: UPLOAD_FILE_PROFESSIONAL_SUCCESS
      })
    } catch (error) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: error.response?.data?.errors,
      })
    }
  }

  // Create card professional
  const createCardProfessionalFile = async ({ professionalId = '', membershipId = '', subscriptionId = '' } = {}, formData) => {
    try {
      setProfessionalLoading(true)
      await axios.post(`/api/v1/reports/professionals/${professionalId}/memberships/${membershipId}/subscriptions/${subscriptionId}/card`, formData, configJSON)
      // Create file
      const resFile = await axios.get(`/api/v1/reports/card.png`, { responseType: 'blob' })
      // Download file
      const pdfBlob = new Blob([resFile.data], { type: 'image/png' })
      saveAs(pdfBlob, `carnet-${Date.now()}.png`)
      dispatch({
        type: CREATE_CARNET_PROFESSIONAL_SUCCESS
      })
      return true
    } catch (error) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: error.response?.data?.errors,
      })
      return false
    }
  }

  // Create certificate professional
  const createCertificateProfessionalFile = async ({ professionalId, membershipId, subscriptionId } = {}, formData) => {
    try {
      setProfessionalLoading(true)
      await axios.post(`/api/v1/reports/professionals/${professionalId}/memberships/${membershipId}/subscriptions/${subscriptionId}/certificate`, formData, configJSON)
      // Create file
      const resFile = await axios.get(`/api/v1/reports/certificate.pdf`, { responseType: 'blob' })
      // Download file
      const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' })
      saveAs(pdfBlob, `certificado-${Date.now()}.pdf`)
      dispatch({
        type: CREATE_CERTIFICATE_PROFESSIONAL_SUCCESS
      })
      return true
    } catch (error) {
      dispatch({
        type: PROFESSIONAL_ERROR,
        payload: error.response?.data?.errors,
      })
      return false
    }
  }

  // Create a new subscription
  const createSubscription = async (params, formData) => {
    const { professionalId, membershipId } = params
    try {
      setSubscriptionLoading()
      await axios.post(`/api/v1/professionals/${professionalId}/memberships/${membershipId}/subscriptions`, formData, configJSON)
      dispatch({
        type: CREATE_SUBSCRIPTION_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: SUBSCRIPTION_ERROR,
        payload: error.response?.data?.errors,
      })
    }
  }

  // Delete subscription with payments
  const deleteSubscription = async (params) => {
    const { professionalId, membershipId, subscriptionId } = params
    try {
      setSubscriptionLoading()
      await axios.delete(`/api/v1/professionals/${professionalId}/memberships/${membershipId}/subscriptions/${subscriptionId}`)
      dispatch({
        type: DELETE_SUBSCRIPTION_SUCCESS,
      })
    } catch (err) {
      dispatch({
        type: SUBSCRIPTION_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Create a new payment subscription per month
  const createPaymentPerMonth = async (params, formData, options) => {
    const { professionalId, membershipId, subscriptionId } = params
    const { enqueueSnackbar } = options
    try {
      setPaymentLoading()
      await axios.post(`/api/v1/professionals/${professionalId}/memberships/${membershipId}/subscriptions/${subscriptionId}/payments/month`, formData, configJSON)
      dispatch({
        type: CREATE_PAYMENT_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (error) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: error.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
      return false
    }
  }

  // Create a new payment subscription per year
  const createPaymentPerYear = async (params, formData, options) => {
    const { professionalId, membershipId, subscriptionId } = params
    const { enqueueSnackbar } = options
    try {
      setPaymentLoading()
      await axios.post(`/api/v1/professionals/${professionalId}/memberships/${membershipId}/subscriptions/${subscriptionId}/payments/year`, formData, configJSON)
      dispatch({
        type: CREATE_PAYMENT_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (error) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: error.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
      return false
    }
  }

  // Delete payment type month of professional
  const deletePaymentPerMonth = async (params, enqueueSnackbar) => {
    const { professionalId, membershipId, subscriptionId, paymentId } = params
    try {
      setPaymentLoading()
      await axios.delete(`/api/v1/professionals/${professionalId}/memberships/${membershipId}/subscriptions/${subscriptionId}/payments/${paymentId}/month`)
      dispatch({
        type: DELETE_PAYMENT_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (err) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
      return false
    }
  }

  // Delete payment type year of professional
  const deletePaymentPerYear = async (params, enqueueSnackbar) => {
    const { professionalId, membershipId, subscriptionId, paymentId } = params
    try {
      setPaymentLoading()
      await axios.delete(`/api/v1/professionals/${professionalId}/memberships/${membershipId}/subscriptions/${subscriptionId}/payments/${paymentId}/year`)
      dispatch({
        type: DELETE_PAYMENT_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (err) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
      return false
    }
  }

  // Set professional loading
  const setProfessionalLoading = async (state) => {
    dispatch({
      type: SET_LOADING_PROFESSIONAL,
      payload: state
    })
  }

  // Set subscription loading
  const setSubscriptionLoading = async () => {
    dispatch({
      type: SET_LOADING_SUBSCRIPTION,
    })
  }

  // Set payment loading
  const setPaymentLoading = async () => {
    dispatch({
      type: SET_LOADING_PAYMENT,
    })
  }

  // Clear professional errors
  const clearProfessionalErrors = async () => {
    dispatch({
      type: CLEAR_ERRORS_PROFESSIONAL,
    })
  }

  // Clear subscription errors
  const clearSubscriptionErrors = async () => {
    dispatch({
      type: CLEAR_ERRORS_SUBSCRIPTION,
    })
  }

  // Reset payment selected
  const resetSubscription = async () => {
    dispatch({
      type: RESET_SUBSCRIPTION,
    })
  }

  // Reset payment
  const resetPayment = async () => {
    dispatch({
      type: RESET_PAYMENT,
    })
  }

  // Clear professional data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_PROFESSIONAL,
    })
  }

  return (
    <ProfessionalContext.Provider
      value={{
        professionals: state.professionals,
        professional: state.professional,
        errorsProfessional: state.errorsProfessional,
        loadingProfessional: state.loadingProfessional,
        createSubscriptionSuccess: state.createSubscriptionSuccess,
        deleteSubscriptionSuccess: state.deleteSubscriptionSuccess,
        loadingSubscription: state.loadingSubscription,
        errorsSubscription: state.errorsSubscription,
        loadingPayment: state.loadingPayment,
        errorsPayment: state.errorsPayment,
        getProfessionals,
        getProfessional,
        createProfessional,
        createCardProfessionalFile,
        createCertificateProfessionalFile,
        createSubscription,
        createPaymentPerYear,
        createPaymentPerMonth,
        updateProfessional,
        updateStateProfessional,
        deleteSubscription,
        deleteProfessional,
        deletePaymentPerMonth,
        deletePaymentPerYear,
        setProfessionalLoading,
        clearProfessionalErrors,
        clearSubscriptionErrors,
        resetSubscription,
        resetPayment,
        clearData,
      }}
    >
      {props.children}
    </ProfessionalContext.Provider>
  )
}

export default ProfessionalState
