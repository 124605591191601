import {
  GET_AREAS,
  GET_AREA,
  SET_LOADING_AREA,
  CREATE_AREA_SUCCESS,
  UPDATE_AREA_SUCCESS,
  DELETE_AREA_SUCCESS,
  AREA_ERROR,
  CLEAR_ERRORS_AREA,
  CLEAR_DATA_AREA,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_AREAS:
      return {
        ...state,
        areas: action.payload,
        errors: null,
        loading: false,
      };
    case GET_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case SET_LOADING_AREA:
      return {
        ...state,
        loading: action.payload,
      };
    case CREATE_AREA_SUCCESS:
    case UPDATE_AREA_SUCCESS:
    case DELETE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        area: null,
        errors: null
      };
    case AREA_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_AREA:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_AREA:
      return {
        ...state,
        areas: null,
        area: null,
        loading: true,
        errors: null,
      };
    default:
      return state;
  }
};
