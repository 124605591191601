import {
  GET_ENTITIES,
  GET_ENTITY,
  GET_SUBSCRIPTIONS_ENTITY,
  GET_SUBSCRIPTION_ENTITY,
  CREATE_SUBSCRIPTION_ENTITY_SUCCESS,
  UPDATE_SUBSCRIPTION_ENTITY_SUCCESS,
  DELETE_SUBSCRIPTION_ENTITY_SUCCESS,
  SET_LOADING_ENTITY,
  SET_SUBSCRIPTION_LOADING_ENTITY,
  ENTITY_ERROR,
  SUBSCRIPTION_ENTITY_ERROR,
  CLEAR_SUBSCRIPTION_SELECTED_ENTITY,
  CLEAR_DATA_ENTITY
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ENTITIES:
      return {
        ...state,
        entities: action.payload,
        errorsEntity: null,
        loadingEntity: false
      };
    case GET_ENTITY:
      return {
        ...state,
        entity: action.payload,
        loadingEntity: false
      };
    case SET_LOADING_ENTITY:
      return {
        ...state,
        loadingEntity: action.payload
      };
    case ENTITY_ERROR:
      return {
        ...state,
        errorsEntity: action.payload,
        loadingEntity: false
      };
    case CLEAR_SUBSCRIPTION_SELECTED_ENTITY:
      return {
        ...state,
        subscription: null,
        loadingSubscription: false,
        errorsSubscription: false
      };
    case GET_SUBSCRIPTIONS_ENTITY:
      return {
        ...state,
        subscriptions: action.payload,
        errorsSubscription: null,
        loadingSubscription: false
      };
    case GET_SUBSCRIPTION_ENTITY:
      return {
        ...state,
        subscription: action.payload,
        loadingSubscription: false
      };
    case CREATE_SUBSCRIPTION_ENTITY_SUCCESS:
    case UPDATE_SUBSCRIPTION_ENTITY_SUCCESS:
    case DELETE_SUBSCRIPTION_ENTITY_SUCCESS:
      return {
        ...state,
        subscription: null,
        loadingSubscription: false,
        errorsSubscription: null
      }
    case SET_SUBSCRIPTION_LOADING_ENTITY:
      return {
        ...state,
        loadingSubscription: action.payload
      };
    case SUBSCRIPTION_ENTITY_ERROR:
      return {
        ...state,
        errorsSubscription: action.payload,
        loadingSubscription: false
      };
    case CLEAR_DATA_ENTITY:
      return {
        entities: null,
        entity: null,
        loadingEntity: true,
        errorsEntity: null,
        subscriptions: null,
        subscription: null,
        loadingSubscription: true,
        errorsSubscription: null
      };
    default:
      return state;
  }
};
