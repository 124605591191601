import React from 'react'
import axios from 'axios'

import ConfigContext from './configContext'

const ConfigState = (props) => {
  // Get env server max mb image upload
  const getMaxSizeImageUpload = async () => {
    try {
      const res = await axios.get('/api/v1/env/max-mb-size-image-upload')
      return res.data
    } catch (error) {
      console.log(error);
    }
  }

  // Get env server max mb file upload
  const getMaxSizeFileUpload = async () => {
    try {
      const res = await axios.get('/api/v1/env/max-mb-size-file-upload')
      return res.data
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ConfigContext.Provider
      value={{
        getMaxSizeImageUpload,
        getMaxSizeFileUpload,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigState
