import React, { useReducer } from 'react';
import axios from 'axios';
import DepartmentContext from './departmentContext';
import departmentReducer from './departmentReducer';
import {
  GET_DEPARTMENTS,
  SET_LOADING_DEPARTMENT,
  DEPARTMENT_ERROR,
  CLEAR_DEPARTMENT_DATA,
} from '../types';

const DepartmentState = (props) => {
  const initialState = {
    departments: null,
    loading: true,
    errors: null,
  };

  const [state, dispatch] = useReducer(departmentReducer, initialState);

  // Fetch all departments
  const getDepartments = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/departments');
      dispatch({
        type: GET_DEPARTMENTS,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DEPARTMENT_ERROR,
        payload: err.response.data?.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_DEPARTMENT,
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DEPARTMENT_DATA,
    });
  };

  return (
    <DepartmentContext.Provider
      value={{
        departments: state.departments,
        errors: state.errors,
        loading: state.loading,
        getDepartments,
        clearData,
      }}
    >
      {props.children}
    </DepartmentContext.Provider>
  );
};

export default DepartmentState;
