import React, {
  useReducer,
  useContext
} from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import AuthContext from './authContext';
import authReducer from './authReducer';
import UserContext from '../user/userContext'
import EntityContext from '../entity/entityContext';
import AreaContext from '../area/areaContext';
import OfficeContext from '../office/officeContext';
import PersonContext from '../person/personContext';
import FixedAssetContext from '../fixedAsset/fixedAssetContext';
import ReportContext from '../report/reportContext';
import { configJSON } from '../../config/requestConfig';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  CLEAR_AUTH_ERRORS,
} from '../types';

const cookie = new Cookies();

const AuthState = (props) => {
  const userContext = useContext(UserContext)
  const entityContext = useContext(EntityContext);
  const areaContext = useContext(AreaContext);
  const officeContext = useContext(OfficeContext);
  const personContext = useContext(PersonContext);
  const fixedAssetContext = useContext(FixedAssetContext);
  const reportContext = useContext(ReportContext);
  const { clearData: clearUserData } = userContext
  const { clearData: clearEntityData } = entityContext
  const { clearData: clearAreaData } = areaContext
  const { clearData: clearOfficeData } = officeContext
  const { clearData: clearPersonData } = personContext
  const { clearData: clearFixedAssetData } = fixedAssetContext
  const { clearData: clearReportData } = reportContext

  const initialState = {
    token: cookie.get('token'),
    isAuthenticated: null,
    loading: true,
    user: null,
    errors: null,
    info: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Login user
  const login = async (formData) => {
    try {
      const res = await axios.post(`/api/v1/auth/login`, formData, configJSON);

      await dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      await loadUser();
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
        payload: error?.response?.data?.errors,
      });
    }
  };

  // Load User
  const loadUser = async () => {
    try {
      const res = await axios.get(`/api/v1/auth/me`);

      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (error) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Logout
  const logout = async () => {
    try {
      await axios.get(`/api/v1/auth/logout`);
      // Clear store data
      clearUserData()
      clearEntityData()
      clearAreaData()
      clearOfficeData()
      clearPersonData()
      clearFixedAssetData()
      clearReportData()

      dispatch({
        type: LOGOUT,
      });
    } catch (error) {
      dispatch({
        type: LOGOUT,
        payload: error?.response?.data?.errors,
      });
    }
  };

  // Clear errors
  const clearAuthErrors = () => {
    dispatch({
      type: CLEAR_AUTH_ERRORS,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        errors: state.errors,
        info: state.info,
        login,
        loadUser,
        logout,
        clearAuthErrors,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
