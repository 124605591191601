import React, { useReducer } from 'react'
import axios from 'axios'

import {
  configJSON,
  // configMultiPart
} from '../../config/requestConfig'
import EntityContext from './entityContext'
import entityReducer from './entityReducer'
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar'
import {
  GET_ENTITIES,
  GET_ENTITY,
  GET_SUBSCRIPTIONS_ENTITY,
  GET_SUBSCRIPTION_ENTITY,
  SET_LOADING_ENTITY,
  SET_SUBSCRIPTION_LOADING_ENTITY,
  CREATE_SUBSCRIPTION_ENTITY_SUCCESS,
  UPDATE_SUBSCRIPTION_ENTITY_SUCCESS,
  DELETE_SUBSCRIPTION_ENTITY_SUCCESS,
  // UPLOAD_CARD_SUBSCRIPTION_SUCCESS,
  // UPLOAD_CERTIFICATE_SUBSCRIPTION_SUCCESS,
  ENTITY_ERROR,
  SUBSCRIPTION_ENTITY_ERROR,
  CLEAR_SUBSCRIPTION_SELECTED_ENTITY,
  CLEAR_DATA_ENTITY,
} from '../types'

const EntityState = (props) => {
  const initialState = {
    entities: null,
    entity: null,
    loadingEntity: true,
    errorsEntity: null,
    subscriptions: null,
    subscription: null,
    loadingSubscription: true,
    errorsSubscription: null
  }

  const [state, dispatch] = useReducer(entityReducer, initialState)

  // Fetch all entity
  const getEntities = async () => {
    try {
      setEntityLoading(true)
      const res = await axios.get('/api/v1/entities')
      dispatch({
        type: GET_ENTITIES,
        payload: res.data?.data,
      })
    } catch (err) {
      dispatch({
        type: ENTITY_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Fetch subscriptions available from entities by professional
  const getSubscriptionsAvailable = async (professionalId) => {
    try {
      setEntityLoading(true)
      const res = await axios.get(`/api/v1/entities/professional/${professionalId}/subscriptions-available`)
      dispatch({
        type: GET_ENTITIES,
        payload: res.data?.data,
      })
    } catch (err) {
      dispatch({
        type: ENTITY_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Fetch all subscriptions and months (entity)
  const getSubscriptionAndMonthsAvailable = async (professionalId) => {
    try {
      setEntityLoading(true)
      const res = await axios.get(`/api/v1/entities/professional/${professionalId}/months-available`)
      dispatch({
        type: GET_ENTITIES,
        payload: res.data?.data,
      })
    } catch (err) {
      dispatch({
        type: ENTITY_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Fetch single entity
  const getEntity = async (id) => {
    try {
      setEntityLoading(true)
      const res = await axios.get(`/api/v1/entities/${id}`)
      dispatch({
        type: GET_ENTITY,
        payload: res.data?.data,
      })
    } catch (err) {
      dispatch({
        type: ENTITY_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Fetch subscriptions entity
  const getSubscriptions = async (entityId) => {
    try {
      setSubscriptionLoading(true)
      const res = await axios.get(`/api/v1/entities/${entityId}/subscriptions`)
      dispatch({
        type: GET_SUBSCRIPTIONS_ENTITY,
        payload: res.data?.data,
      })
    } catch (err) {
      dispatch({
        type: SUBSCRIPTION_ENTITY_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Fetch single subscription entity
  const getSubscription = async (entityId, managementId) => {
    try {
      // setSubscriptionLoading(true)
      const res = await axios.get(`/api/v1/entities/${entityId}/subscriptions/${managementId}`)
      dispatch({
        type: GET_SUBSCRIPTION_ENTITY,
        payload: res.data?.data,
      })
    } catch (err) {
      dispatch({
        type: SUBSCRIPTION_ENTITY_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Add a new subscription entity
  const addSubscription = async (entityId, formData,
    //  formCardFile, formCertificateFile,
    enqueueSnackbar) => {
    try {
      setSubscriptionLoading(true)
      await axios.post(`/api/v1/entities/${entityId}/subscriptions`, formData, configJSON)
      dispatch({
        type: CREATE_SUBSCRIPTION_ENTITY_SUCCESS,
      })
      // // Get id created
      // const subscriptionId = res.data?.data?._id;
      // // Upload card
      // if (formCardFile.get('card') instanceof File) {
      //   await uploadCardSubscription(formCardFile, entityId, subscriptionId)
      // }
      // // Upload certificate
      // if (formCertificateFile.get('certificate') instanceof File) {
      //   await uploadCertificateSubscription(formCertificateFile, entityId, subscriptionId)
      // }
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (error) {
      dispatch({
        type: SUBSCRIPTION_ENTITY_ERROR,
        payload: error.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Update subscription entity
  const updateSubscription = async (entityId, managementId, formData, enqueueSnackbar) => {
    try {
      setSubscriptionLoading(true)
      await axios.put(`/api/v1/entities/${entityId}/subscriptions/${managementId}`, formData, configJSON)
      dispatch({
        type: UPDATE_SUBSCRIPTION_ENTITY_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (error) {
      dispatch({
        type: SUBSCRIPTION_ENTITY_ERROR,
        payload: error.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Delete subscription entity
  const deleteSubscription = async (entityId, subscriptionId, enqueueSnackbar) => {
    try {
      setSubscriptionLoading(true)
      await axios.delete(`/api/v1/entities/${entityId}/subscriptions/${subscriptionId}`)
      dispatch({
        type: DELETE_SUBSCRIPTION_ENTITY_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (err) {
      dispatch({
        type: SUBSCRIPTION_ENTITY_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Upload card file
  // const uploadCardSubscription = async (formDataFile, entityId, subscriptionId) => {
  //   try {
  //     // setSubscriptionLoading(true)
  //     // Return path file uploaded
  //     await axios.post(
  //       `/api/v1/uploads/entities/${entityId}/subscriptions/${subscriptionId}/card`,
  //       formDataFile,
  //       
  // configMultiPart

  //     )
  //     dispatch({
  //       type: UPLOAD_CARD_SUBSCRIPTION_SUCCESS
  //     })
  //   } catch (error) {
  //     dispatch({
  //       type: SUBSCRIPTION_ENTITY_ERROR,
  //       payload: error.response?.data?.errors,
  //     })
  //   }
  // }

  // Upload certificate file
  // const uploadCertificateSubscription = async (formDataFile, entityId, subscriptionId) => {
  //   try {
  //     // setSubscriptionLoading(true)
  //     // Return path file uploaded
  //     await axios.post(
  //       `/api/v1/uploads/entities/${entityId}/subscriptions/${subscriptionId}/certificate`,
  //       formDataFile,
  //       
  // configMultiPart

  //     )
  //     dispatch({
  //       type: UPLOAD_CERTIFICATE_SUBSCRIPTION_SUCCESS
  //     })
  //   } catch (error) {
  //     dispatch({
  //       type: SUBSCRIPTION_ENTITY_ERROR,
  //       payload: error.response?.data?.errors,
  //     })
  //   }
  // }

  // Set Loading
  const setEntityLoading = async (state) => {
    dispatch({
      type: SET_LOADING_ENTITY,
      payload: state
    })
  }

  // Set subscription loading
  const setSubscriptionLoading = async (state) => {
    dispatch({
      type: SET_SUBSCRIPTION_LOADING_ENTITY,
      payload: state
    })
  }

  // Clear subscription data
  const clearSubscriptionSelected = async () => {
    dispatch({
      type: CLEAR_SUBSCRIPTION_SELECTED_ENTITY,
    })
  }

  // Clear entity data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_ENTITY,
    })
  }

  return (
    <EntityContext.Provider
      value={{
        entities: state.entities,
        entity: state.entity,
        loadingEntity: state.loadingEntity,
        errorsEntity: state.errorsEntity,
        subscriptions: state.subscriptions,
        subscription: state.subscription,
        loadingSubscription: state.loadingSubscription,
        errorsSubscription: state.errorsSubscription,
        getEntities,
        getSubscriptionsAvailable,
        getSubscriptionAndMonthsAvailable,
        getEntity,
        getSubscriptions,
        getSubscription,
        addSubscription,
        updateSubscription,
        deleteSubscription,
        setSubscriptionLoading,
        clearSubscriptionSelected,
        clearData,
      }}
    >
      {props.children}
    </EntityContext.Provider>
  )
}

export default EntityState
