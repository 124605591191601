import React, { useReducer } from 'react';
import axios from 'axios';
import ProviderContext from './providerContext';
import providerReducer from './providerReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import { configJSON } from '../../config/requestConfig';
import {
  GET_PROVIDERS,
  GET_PROVIDER,
  SET_LOADING_PROVIDER,
  PROVIDER_CREATE_SUCCESS,
  PROVIDER_UPDATE_SUCCESS,
  PROVIDER_DELETE_SUCCESS,
  PROVIDER_ERROR,
  CLEAR_DATA_PROVIDER,
} from '../types';

const ProviderState = (props) => {
  const initialState = {
    providers: null,
    provider: null,
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(providerReducer, initialState);

  // Fetch all providers
  const getProviders = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/api/v1/providers');
      dispatch({
        type: GET_PROVIDERS,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: PROVIDER_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Fetch single provider
  const getProvider = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/providers/${id}`);
      dispatch({
        type: GET_PROVIDER,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: PROVIDER_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Create a new provider
  const createProvider = async (formData, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.post('/api/v1/providers', formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: PROVIDER_CREATE_SUCCESS,
      });
      return true
    } catch (error) {
      dispatch({
        type: PROVIDER_ERROR,
        payload: error.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Update provider
  const updateProvider = async (id, formData, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/providers/${id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: PROVIDER_UPDATE_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: PROVIDER_ERROR,
        payload: err.response.data.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Delete provider
  const deleteProvider = async (id, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/providers/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: PROVIDER_DELETE_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: PROVIDER_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_PROVIDER,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_PROVIDER,
    });
  };

  return (
    <ProviderContext.Provider
      value={{
        providers: state.providers,
        provider: state.provider,
        errors: state.errors,
        loading: state.loading,
        getProviders,
        getProvider,
        createProvider,
        updateProvider,
        deleteProvider,
        setLoading,
        clearData,
      }}
    >
      {props.children}
    </ProviderContext.Provider>
  );
};

export default ProviderState;
