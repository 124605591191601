import React, { useReducer } from 'react'
import axios from 'axios'

import { configJSON } from '../../config/requestConfig'
import UserContext from './userContext'
import userReducer from './userReducer'
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar'
import {
  GET_USERS,
  GET_USER,
  SET_LOADING_USERS,
  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_STATE_USER_SUCCESS,
  UPDATE_AUTH_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  USER_ERROR,
  CLEAR_ERRORS_USER,
  CLEAR_DATA_USER,
} from '../types'

const UserState = (props) => {

  const initialState = {
    users: null,
    user: null,
    loading: true,
    errors: null,
  }

  const [state, dispatch] = useReducer(userReducer, initialState)

  // Fetch all users
  const getUsers = async (keyword = '', pageNumber = '') => {
    try {
      setLoading(false)
      const res = await axios.get(`/api/v1/users?keyword=${keyword}&pageNumber=${pageNumber}`)
      dispatch({
        type: GET_USERS,
        payload: res.data?.data,
      })
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Fetch single user
  const getUser = async (id) => {
    try {
      setLoading(false)
      const res = await axios.get(`/api/v1/users/${id}`)
      dispatch({
        type: GET_USER,
        payload: res.data?.data,
      })
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors,
      })
    }
  }

  // Create a new user
  const createUser = async (formData, history, enqueueSnackbar) => {
    try {
      setLoading(false)
      await axios.post('/api/v1/users', formData, configJSON)
      dispatch({
        type: CREATE_USER_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      history.push('/app/users')
    } catch (error) {
      dispatch({
        type: USER_ERROR,
        payload: error.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Update user
  const updateUser = async (_id, formData, history, enqueueSnackbar) => {
    try {
      setLoading(false)
      await axios.put(`/api/v1/users/${_id}`, formData, configJSON)
      dispatch({
        type: UPDATE_USER_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      history.push('/app/users')
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Update auth user
  const updateAuthUser = async (_id, formData, history, enqueueSnackbar) => {
    try {
      setLoading(false)
      await axios.put(`/api/v1/users/auth/${_id}`, formData, configJSON)
      dispatch({
        type: UPDATE_AUTH_USER_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      history.push('/app/users')
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Update state user
  const updateStateUser = async (_id, formData, enqueueSnackbar) => {
    try {
      setLoading(false)
      await axios.patch(`/api/v1/users/${_id}`, formData, configJSON)
      dispatch({
        type: UPDATE_STATE_USER_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Delete user
  const deleteUser = async (id, enqueueSnackbar) => {
    try {
      setLoading(false)
      await axios.delete(`/api/v1/users/auth/${id}`)
      dispatch({
        type: DELETE_USER_SUCCESS,
      })
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB)
      return true
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors,
      })
      enqueueSnackbar(msgFailureSB, optionsFailureSB)
    }
  }

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_USERS,
      payload: state
    })
  }

  // Clear Errors
  const clearErrors = async () => {
    dispatch({
      type: CLEAR_ERRORS_USER,
    })
  }

  // Clear user data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_USER,
    })
  }

  return (
    <UserContext.Provider
      value={{
        users: state.users,
        user: state.user,
        errors: state.errors,
        loading: state.loading,
        getUsers,
        getUser,
        createUser,
        updateUser,
        updateStateUser,
        updateAuthUser,
        deleteUser,
        setLoading,
        clearErrors,
        clearData,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserState
