import {
  GET_OFFICES,
  GET_OFFICE,
  SET_LOADING_OFFICE,
  CREATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_SUCCESS,
  DELETE_OFFICE_SUCCESS,
  OFFICE_ERROR,
  CLEAR_ERRORS_OFFICE,
  CLEAR_DATA_OFFICE,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_OFFICES:
      return {
        ...state,
        offices: action.payload,
        errors: null,
        loading: false,
      };
    case GET_OFFICE:
      return {
        ...state,
        office: action.payload,
        loading: false,
      };
    case SET_LOADING_OFFICE:
      return {
        ...state,
        loading: action.payload,
      };
    case CREATE_OFFICE_SUCCESS:
    case UPDATE_OFFICE_SUCCESS:
    case DELETE_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        office: null,
        errors: null
      };
    case OFFICE_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_OFFICE:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_OFFICE:
      return {
        ...state,
        offices: null,
        office: null,
        loading: true,
        errors: null,
      };
    default:
      return state;
  }
};
