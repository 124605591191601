import {
  GET_PROVIDERS,
  GET_PROVIDER,
  SET_LOADING_PROVIDER,
  PROVIDER_CREATE_SUCCESS,
  PROVIDER_UPDATE_SUCCESS,
  PROVIDER_DELETE_SUCCESS,
  PROVIDER_ERROR,
  CLEAR_DATA_PROVIDER,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
        errors: null,
        loading: false,
      };
    case GET_PROVIDER:
      return {
        ...state,
        provider: action.payload,
        loading: false,
        errors: null,
      };
    case SET_LOADING_PROVIDER:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case PROVIDER_CREATE_SUCCESS:
    case PROVIDER_UPDATE_SUCCESS:
    case PROVIDER_DELETE_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case PROVIDER_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_DATA_PROVIDER:
      return {
        ...state,
        providers: null,
        provider: null,
        loading: true,
        errors: null,
      };
    default:
      return state;
  }
};
