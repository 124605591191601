import React, { useReducer } from 'react';
import axios from 'axios';
import VerificationContext from './verificationContext';
import verificationReducer from './verificationReducer';
import {
  GET_FIXED_ASSET_VERIFICATION,
  GET_PROFESSIONAL_VERIFICATION,
  SET_LOADING_VERIFICATION,
  FIXED_ASSET_VERIFICATION_ERROR,
  PROFESSIONAL_VERIFICATION_ERROR,
  CLEAR_DATA_FIXED_ASSET_VERIFICATION,
} from '../types';

const VerificationState = (props) => {
  const initialState = {
    fixedAsset: null,
    professional: null,
    loading: true,
    errors: null,
  };

  const [state, dispatch] = useReducer(verificationReducer, initialState);

  // Fetch fixed asset verification
  const getFixedAsset = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/verifications/fixed-assets/${id}`);
      dispatch({
        type: GET_FIXED_ASSET_VERIFICATION,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: FIXED_ASSET_VERIFICATION_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Fetch card professional verification
  const getProfessionalVerification = async ({ professionalId = '', membershipIndex = '', subscriptionIndex = '' } = {}) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/verifications/professionals/${professionalId}?membershipIndex=${membershipIndex}&subscriptionIndex=${subscriptionIndex}`);
      dispatch({
        type: GET_PROFESSIONAL_VERIFICATION,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: PROFESSIONAL_VERIFICATION_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_VERIFICATION,
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_FIXED_ASSET_VERIFICATION,
    });
  };

  return (
    <VerificationContext.Provider
      value={{
        fixedAsset: state.fixedAsset,
        professional: state.professional,
        errors: state.errors,
        loading: state.loading,
        getFixedAsset,
        getProfessionalVerification,
        clearData,
      }}
    >
      {props.children}
    </VerificationContext.Provider>
  );
};

export default VerificationState;
