import {
  GET_USERS,
  GET_USER,
  SET_LOADING_USERS,
  USER_ERROR,
  CLEAR_ERRORS_USER,
  CLEAR_DATA_USER
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        errors: null,
        loading: false
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case SET_LOADING_USERS:
      return {
        ...state,
        loading: action.payload
      };
    case USER_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_ERRORS_USER:
      return {
        ...state,
        errors: null,
        loading: false
      };
    case CLEAR_DATA_USER:
      return {
        users: null,
        user: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
