import {
  GET_PERSONS,
  GET_PERSON,
  SET_LOADING_PERSON,
  CREATE_PERSON_SUCCESS,
  UPDATE_PERSON_SUCCESS,
  DELETE_PERSON_SUCCESS,
  PERSON_ERROR,
  CLEAR_ERRORS_PERSON,
  CLEAR_DATA_PERSON,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_PERSONS:
      return {
        ...state,
        persons: action.payload,
        errors: null,
        loading: false,
      };
    case GET_PERSON:
      return {
        ...state,
        person: action.payload,
        loading: false,
      };
    case SET_LOADING_PERSON:
      return {
        ...state,
        loading: action.payload,
      };
    case CREATE_PERSON_SUCCESS:
    case UPDATE_PERSON_SUCCESS:
    case DELETE_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        person: null,
        errors: null
      };
    case PERSON_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_PERSON:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_PERSON:
      return {
        ...state,
        persons: null,
        person: null,
        loading: true,
        errors: null,
      };
    default:
      return state;
  }
};
