import {
  GET_FIXED_ASSETS,
  GET_FIXED_ASSETS_QR_FILE,
  GET_FIXED_ASSET,
  SET_LOADING_FIXED_ASSET,
  FIXED_ASSET_ERROR,
  CLEAR_ERRORS_FIXED_ASSET,
  CLEAR_DATA_FIXED_ASSET,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_FIXED_ASSETS:
      return {
        ...state,
        fixedAssets: action.payload,
        errors: null,
        loading: false,
      };
    case GET_FIXED_ASSETS_QR_FILE:
      return {
        ...state,
        loading: false,
        errors: null,
      };
    case GET_FIXED_ASSET:
      return {
        ...state,
        fixedAsset: action.payload,
        loading: false,
      };
    case SET_LOADING_FIXED_ASSET:
      return {
        ...state,
        loading: action.payload,
      };
    case FIXED_ASSET_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_FIXED_ASSET:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_FIXED_ASSET:
      return {
        ...state,
        fixedAssets: null,
        fixedAsset: null,
        loading: true,
        errors: null,
      };
    default:
      return state;
  }
};
