import React, { useReducer } from 'react';
import axios from 'axios';
import AreaContext from './areaContext';
import areaReducer from './areaReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import { configJSON } from '../../config/requestConfig';
import {
  GET_AREAS,
  GET_AREA,
  SET_LOADING_AREA,
  CREATE_AREA_SUCCESS,
  UPDATE_AREA_SUCCESS,
  DELETE_AREA_SUCCESS,
  AREA_ERROR,
  CLEAR_ERRORS_AREA,
  CLEAR_DATA_AREA,
} from '../types';

const AreaState = (props) => {
  const initialState = {
    areas: null,
    area: null,
    loading: true,
    errors: null,
  };

  const [state, dispatch] = useReducer(areaReducer, initialState);

  // Fetch all areas
  const getAreas = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/areas`);
      dispatch({
        type: GET_AREAS,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: AREA_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Fetch all areas
  const getAreasByOffice = async (officeId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/areas?office=${officeId}`);
      dispatch({
        type: GET_AREAS,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: AREA_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Fetch single area
  const getArea = async (id) => {
    try {
      // setLoading(true);
      const res = await axios.get(`/api/v1/areas/${id}`);
      dispatch({
        type: GET_AREA,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: AREA_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Create a new area
  const createArea = async (formData, options = {}) => {
    const { enqueueSnackbar } = options
    try {
      setLoading(true);
      await axios.post('/api/v1/areas', formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: CREATE_AREA_SUCCESS,
      });
      return true
    } catch (error) {
      dispatch({
        type: AREA_ERROR,
        payload: error.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Update area
  const updateArea = async (id, formData, options = {}) => {
    const { enqueueSnackbar } = options
    try {
      setLoading(true);
      await axios.put(`/api/v1/areas/${id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: UPDATE_AREA_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: AREA_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Delete area
  const deleteArea = async (id, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/areas/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: DELETE_AREA_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: AREA_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_AREA,
      payload: state
    });
  };

  // Clear errors
  const clearErrors = async () => {
    dispatch({
      type: CLEAR_ERRORS_AREA,
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_AREA,
    });
  };

  return (
    <AreaContext.Provider
      value={{
        areas: state.areas,
        area: state.area,
        errors: state.errors,
        loading: state.loading,
        getAreas,
        getAreasByOffice,
        getArea,
        createArea,
        updateArea,
        deleteArea,
        setLoading,
        clearErrors,
        clearData,
      }}
    >
      {props.children}
    </AreaContext.Provider>
  );
};

export default AreaState;