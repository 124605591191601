import React, { useReducer } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

import TransactionContext from './transactionContext';
import transactionReducer from './transactionReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import { configJSON } from '../../config/requestConfig';
import {
  GET_TRANSACTIONS,
  GET_TRANSACTION,
  SET_LOADING_TRANSACTION,
  TRANSACTION_CREATE_SUCCESS,
  TRANSACTION_UPDATE_SUCCESS,
  TRANSACTION_DELETE_SUCCESS,
  TRANSACTION_PDF_SUCCESS,
  TRANSACTION_ERROR,
  CLEAR_DATA_TRANSACTION,
} from '../types';

const TransactionState = (props) => {
  const initialState = {
    transactions: null,
    transaction: null,
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(transactionReducer, initialState);

  // Fetch all transactions
  const getTransactions = async ({ typeTransaction = '', keyword = '', field = '', pageNumber = '', rowsPerPage = '' } = {}) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/transactions?typeTransaction=${typeTransaction}&keyword=${keyword}&field=${field}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`);
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Fetch single transaction
  const getTransaction = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/transactions/${id}`);
      dispatch({
        type: GET_TRANSACTION,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Create a new transaction
  const createTransaction = async (formData, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.post('/api/v1/transactions', formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: TRANSACTION_CREATE_SUCCESS,
      });
      return true
    } catch (error) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: error.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Update transaction
  const updateTransaction = async (id, formData, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/transactions/${id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: TRANSACTION_UPDATE_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.data.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Delete transaction
  const deleteTransaction = async (id, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/transactions/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: TRANSACTION_DELETE_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Fetch expense
  const fetchPDF = async (id, options = {}, enqueueSnackbar) => {
    const { isInvoiceWithCopy = '' } = options
    try {
      setLoading(true);
      await axios.get(`/api/v1/reports/transactions/${id}?isInvoiceWithCopy=${isInvoiceWithCopy}`);

      // Get report generated and download client
      const resFile = await axios.get(`/api/v1/reports/transaction.pdf`, {
        responseType: 'blob',
      });
      const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `egreso-${Date.now()}.pdf`);

      dispatch({
        type: TRANSACTION_PDF_SUCCESS,
      });
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_TRANSACTION,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_TRANSACTION,
    });
  };

  return (
    <TransactionContext.Provider
      value={{
        transactions: state.transactions,
        transaction: state.transaction,
        errors: state.errors,
        loading: state.loading,
        getTransactions,
        getTransaction,
        createTransaction,
        updateTransaction,
        deleteTransaction,
        fetchPDF,
        setLoading,
        clearData,
      }}
    >
      {props.children}
    </TransactionContext.Provider>
  );
};

export default TransactionState;
