import React, { useReducer } from 'react';
import axios from 'axios';
import OfficeContext from './officeContext';
import officeReducer from './officeReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import { configJSON } from '../../config/requestConfig';
import {
  GET_OFFICES,
  GET_OFFICE,
  SET_LOADING_OFFICE,
  CREATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_SUCCESS,
  DELETE_OFFICE_SUCCESS,
  OFFICE_ERROR,
  CLEAR_ERRORS_OFFICE,
  CLEAR_DATA_OFFICE,
} from '../types';

const OfficeState = (props) => {
  const initialState = {
    offices: null,
    office: null,
    loading: true,
    errors: null,
  };

  const [state, dispatch] = useReducer(officeReducer, initialState);

  // Fetch all offices
  const getOffices = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/offices`);
      dispatch({
        type: GET_OFFICES,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: OFFICE_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Fetch single office
  const getOffice = async (id) => {
    try {
      // setLoading(true);
      const res = await axios.get(`/api/v1/offices/${id}`);
      dispatch({
        type: GET_OFFICE,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: OFFICE_ERROR,
        payload: err.response?.data?.errors,
      });
    }
  };

  // Create a new office
  const createOffice = async (formData, options = {}) => {
    const { enqueueSnackbar } = options
    try {
      setLoading(true);
      await axios.post('/api/v1/offices', formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: CREATE_OFFICE_SUCCESS,
      });
      return true
    } catch (error) {
      dispatch({
        type: OFFICE_ERROR,
        payload: error.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Update office
  const updateOffice = async (id, formData, options = {}) => {
    const { enqueueSnackbar } = options
    try {
      setLoading(true);
      await axios.put(`/api/v1/offices/${id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: UPDATE_OFFICE_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: OFFICE_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Delete office
  const deleteOffice = async (id, enqueueSnackbar) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/offices/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: DELETE_OFFICE_SUCCESS,
      });
      return true
    } catch (err) {
      dispatch({
        type: OFFICE_ERROR,
        payload: err.response?.data?.errors,
      });
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_OFFICE,
      payload: state
    });
  };

  // Clear errors
  const clearErrors = async () => {
    dispatch({
      type: CLEAR_ERRORS_OFFICE,
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_OFFICE,
    });
  };

  return (
    <OfficeContext.Provider
      value={{
        offices: state.offices,
        office: state.office,
        errors: state.errors,
        loading: state.loading,
        getOffices,
        getOffice,
        createOffice,
        updateOffice,
        deleteOffice,
        setLoading,
        clearErrors,
        clearData,
      }}
    >
      {props.children}
    </OfficeContext.Provider>
  );
};

export default OfficeState;
